export const symptomCheckerData = {
    Head: [
      'Headache',
      'Face pain',
      'Sinus pain or pressure',
      'Fever',
      'Swollen face',
      'Face tingling or numbness',
      'Dizzy',
      'Feeling sick or queasy',
      'Recent head injury',
      'Concussion (diagnosed)',
      'Confusion',
      'Lightheaded',
      'Disoriented',
      'None of Above',
    ],
    Eye: [
        'Eye pain',
        'Red eye',
        'Itchy eye',
        'Watery eyes',
        'Visual impairment',
        'Double vision',
        'Temporary blindness',
        'Asymmetric pupils',
        'Swelling around both eyes',
        'Drooping eyelids',
        'Eye flashes',
        'Light sensitivity',
        'Blurred vision',
        'Eye irritation',
        'Dilated (enlarged) pupils',
        'None of Above',
      ],
      Nose: [
        'Painful nose',
        'Swollen nose',
        'Runny nose',
        'Blocked nose',
        'Itchy nose or throat',
        'Sneezing',
        'Bleeding from nose',
        'Nose injury',
        'None of Above',
      ],
      Ear: [
        'Earache',
        'Decreased hearing',
        'Discharge from ear',
        'Clogged ear',
        'Itchy inner ear',
        'Ringing in ears',
        'Ear injury',
        'Fluid from ear',
        'Ear rubbing',
        'None of Above',
      ],
      Mouth: [
        'Vomiting',
        'Bad breath',
        'Dry mouth',
        'Toothache',
        'Difficulty biting and chewing',
        'Gum pain',
        'Swollen gums',
        'Bleeding gums',
        'Cough',
        'Coughing up blood',
        'Diminished appetite',
        'More thirsty than usual',
        'White spots on tonsils',
        'Lip injury',
        'Mouth ulcer',
        'Lock jaw, or difficulty opening mouth',
        'None of Above',
      ],
      Throat: [
        'Sore throat',
        'Painful swallowing',
        'Difficulty swallowing',
        'Red throat',
        'Clearing the throat',
        'Mucus dripping at the back of the throat',
        'Hoarse voice',
        'Enlarged lymph nodes',
        'Swollen neck',
        'Stiff neck',
        'Neck injury',
        'Choking',
        'Grunting',
        'None of Above',
      ],
      Chest: [
        'Asthma (diagnosed)',
        'Chest pain',
        'Heartburn',
        'Palpitations',
        'Fast heart rate',
        'Slow heart rate',
        'Shortness of breath',
        'Shallow breathing',
        'Rapid breathing',
        'Chest injury',
        'Tight chest',
        'Wheezing',
        'None of Above',
      ],
      Breast: [
        'Lump in the breast',
        'Pain or tenderness in breasts',
        'Milky discharge from the nipple outside of breastfeeding',
        'Wrinkling or dimpling of skin on breast',
        'Change in the nipple',
        'None of Above',
      ],
      'Upper Arm': [
        'Shoulder pain',
        'Pain when moving shoulder',
        'Swelling of the upper limb',
        'Tingling or numbness of arms',
        'Arm injury',
        'None of Above',
      ],
      Forearm: [
        'Pain in forearm',
        'Swelling of the upper limb',
        'Swollen elbow',
        'Wrist pain',
        'Forearm injury',
        'None of Above',
      ],
      Hand: [
        'Pain in hand or fingers',
        'Pain of the skin around the nail',
        'Swollen hand',
        'Tingling fingers',
        'Hand injury',
        'None of Above',
      ],      
      'Upper Abdomen': [
        'Stomach pain',
        'Upper central abdominal pain',
        'Burning or gnawing stomach pain',
        'Colic abdominal pain',
        'Sharp and stabbing abdominal pain',
        'Lump in stomach',
        'Bloating',
        'Vomiting',
        'Weight loss',
        'Recent abdominal injury',
        'None of Above',
      ],
      'Middle Abdomen': [
        'Stomach pain',
        'Central abdominal pain',
        'Burning or gnawing stomach pain',
        'Colic abdominal pain',
        'Sharp and stabbing abdominal pain',
        'Lump in stomach',
        'Bloating',
        'Constipation',
        'Diarrhea',
        'Black stool',
        'Red stool',
        'Recent abdominal injury',
        'None of Above',
      ],
      'Lower Abdomen': [
        'Stomach pain',
        'Lower left side abdominal pain',
        'Lower right side abdominal pain',
        'Colic abdominal pain',
        'Crampy abdominal pain',
        'Sharp and stabbing abdominal pain',
        'Bloating',
        'Constipation',
        'Diarrhea',
        'Black stool',
        'Red stool',
        'Recent abdominal injury',
        'None of Above',
      ],
      'Sexual Organs': [
        'Abnormal vaginal discharge',
        'Bleeding after intercourse',
        'Painful sex',
        'Urinating often',
        'Pain when urinating',
        'Dark urine',
        'Red urine',
        'Leaking urine by accident',
        'Blood in urine',
        'Pain in groin or genital area',
        'Itching in crotch',
        'Urination in small amounts',
        'Skin changes on or around genitals',
        'Vaginal bleeding between periods',
        'None of Above',
      ],
      Thigh: [
        'Thigh pain',
        'Hip pain',
        'Lower back pain spreading to thigh or knee',
        'Pain when pressing lower limb',
        'Swollen leg',
        'Tingling or numbness in lower limb',
        'Thigh injury',
        'None of Above',
      ],
      Knee: [
        'Pain in knee',
        'Pain when moving knee',
        'Swollen knee',
        'Knee injury',
        'None of Above',
      ],
      'Lower Leg': [
        'Pain in calf',
        'Swollen leg',
        'Tingling or numbness in lower limb',
        'Lower leg injury',
        'None of Above',
      ],
      Foot: [
        'Pain in foot',
        'Toe pain',
        'Swollen feet',
        'Skin changes on feet',
        'Foot injury',
        'None of Above',
      ],
      Back: [
        'Back pain',
        'Back pain that comes and goes',
        'Back pain worsening after physical activity',
        'Middle or upper back pain',
        'Stiff spine in the morning',
        'Spine asymmetry',
        'Difficulty bending down',
        'Back injury',
        'None of Above',
      ],
      'Lower Back': [
        'Lower back pain',
        'Back pain spreading to groin or thigh',
        'Paraspinal muscle tenderness',
        'None of Above',
      ],
      Buttocks: [
        'Buttocks pain',
        'Hip pain',
        'Anorectal pain',
        'Bleeding from anus',
        'Itchy anus',
        'Anal pain',
        'None of Above',
      ],
      Emotion: [
        'Anxiety',
        'Depression',
        'Suicidal thoughts',
        'Thoughts about self harm',
        'Stress',
        'None of Above',
      ],
      Skin: [
        'Sunburn',
        'Heat rash',
        'Hives',
        'Psoriasis (diagnosed)',
        'Blisters',
        'Skin changes (e.g., redness, swelling)',
        'Jellyfish sting',
        'Scalds',
        'Wound',
        'Wound oozing',
        'None of Above',
      ],
      Others: [
        'Allergic reaction (severe)',
        'Poisoning',
        'Incontinence',
        'Infertility',
        'Insomnia',
        'Swallowed object',
        'Fatigue',
        'Weight gain',
        'Weight loss',
        'Feeling cold',
        'High blood glucose (sugar)',
        'Low blood glucose (sugar)',
        'Dog bite',
        'Insect bite or sting',
        'Snake bite',
        'Sunken fontanelle',
        'Tremors',
        'Nausea',
        'None of Above',
      ]
  };
  
  export const symptomQuestionsData = {
    Headache: {
        questions: [
          {
            question: 'When did the headache start?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'How severe is the headache on a scale of 1 to 10?',
            options: ['1-3 (Mild)', '4-6 (Moderate)', '7-10 (Severe)'],
          },
          {
            question: 'Where is the headache located?',
            options: ['Front', 'Back', 'One side', 'Other'],
          },
          {
            question: 'Is the headache constant or intermittent?',
            options: ['Constant', 'Intermittent'],
          },
          {
            question: 'Have you experienced any visual disturbances or nausea?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      'Face pain': {
        questions: [
          {
            question: 'Where exactly is the pain located on your face?',
            options: ['Cheek', 'Jaw', 'Forehead', 'Other'],
          },
          {
            question: 'Is the pain constant or does it come and go?',
            options: ['Constant', 'Comes and goes'],
          },
          {
            question: 'Are there any other symptoms associated with the face pain?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      'Sinus pain or pressure': {
        questions: [
          {
            question: 'When did the sinus pain start?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Do you have other symptoms like a runny nose or nasal congestion?',
            options: ['Yes', 'No', "Don't know"],
          },
          {
            question: 'Have you recently had a cold or allergy symptoms?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      Fever: {
        questions: [
          {
            question: 'When did the fever start?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'What is your current temperature?',
            options: [
              'Normal (97.8°F / 36.5°C or below)',
              'Low-grade fever (97.8-99.5°F / 36.5-37.5°C)',
              'High fever (99.6°F / 37.5°C or above)',
            ],
          },
          {
            question: 'Have you experienced any chills or sweats?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      'Swollen Face': {
        questions: [
          {
            question: 'When did the swelling start?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the swelling constant or does it fluctuate?',
            options: ['Constant', 'Fluctuates'],
          },
          {
            question: 'Have you experienced any pain or tenderness in the swollen area?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      'Face tingling or numbness': {
        questions: [
          {
            question: 'When did you first notice the tingling or numbness?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the tingling or numbness in one side of the face or both?',
            options: ['One side', 'Both sides'],
          },
          {
            question: 'Have you experienced any weakness or difficulty moving your face?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      Dizzy: {
        questions: [
          {
            question: 'When did you start feeling dizzy?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the dizziness constant or does it occur with certain movements?',
            options: ['Constant', 'With certain movements'],
          },
          {
            question: 'Do you experience any other symptoms like nausea or loss of balance?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      'Feeling sick or queasy': {
        questions: [
          {
            question: 'When did you start feeling sick or queasy?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the feeling constant or does it come and go?',
            options: ['Constant', 'Comes and goes'],
          },
          {
            question: 'Have you experienced any vomiting or diarrhea?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      'Recent head injury': {
        questions: [
          {
            question: 'When did the head injury occur?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Did you lose consciousness or experience severe pain after the injury?',
            options: ['Yes', 'No', "Don't know"],
          },
          {
            question: 'Have you noticed any swelling or bruising at the injury site?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      'Concussion (diagnosed)': {
        questions: [
          {
            question: 'When were you diagnosed with a concussion?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Are you experiencing any new or worsening symptoms?',
            options: ['Yes', 'No', "Don't know"],
          },
          {
            question: 'Have you been advised to rest or avoid certain activities?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      Confusion: {
        questions: [
          {
            question: 'When did you first notice confusion?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the confusion constant or does it come and go?',
            options: ['Constant', 'Comes and goes'],
          },
          {
            question: 'Have you experienced any other symptoms like difficulty speaking or memory loss?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      Disoriented: {
        questions: [
          {
            question: 'When did you first feel disoriented?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the disorientation constant or does it occur at specific times?',
            options: ['Constant', 'Specific times'],
          },
          {
            question: 'Have you experienced any changes in your ability to recognize people or places?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      
      Lightheaded: {
        questions: [
          {
            question: 'When did you start feeling lightheaded?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the lightheadedness constant or does it occur with certain activities?',
            options: ['Constant', 'With certain activities'],
          },
          {
            question: 'Do you experience any associated symptoms like blurred vision or fainting?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },
      Lightheaded: {
        questions: [
          {
            question: 'When did you start feeling lightheaded?',
            options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
          },
          {
            question: 'Is the lightheadedness constant or does it occur with certain activities?',
            options: ['Constant', 'With certain activities'],
          },
          {
            question: 'Do you experience any associated symptoms like blurred vision or fainting?',
            options: ['Yes', 'No', "Don't know"],
          },
        ],
      },

      'Eye pain': {
  questions: [
    {
      question: 'When did the eye pain start?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the pain constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Do you experience any redness or swelling?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Red eye': {
  questions: [
    {
      question: 'When did the redness in your eye start?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the redness in one eye or both?',
      options: ['One eye', 'Both eyes'],
    },
    {
      question: 'Do you have any associated symptoms like itching or discharge?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Itchy eye': {
  questions: [
    {
      question: 'When did the itching start?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the itching in one eye or both?',
      options: ['One eye', 'Both eyes'],
    },
    {
      question: 'Have you been exposed to any allergens recently?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Watery eyes': {
  questions: [
    {
      question: 'When did your eyes start watering?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the watering constant or does it come and go?',
      options: ['Constant', 'Comes and goes'],
    },
    {
      question: 'Are there any other symptoms like redness or itching?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Visual impairment': {
  questions: [
    {
      question: 'When did you first notice visual impairment?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the impairment in one eye or both?',
      options: ['One eye', 'Both eyes'],
    },
    {
      question: 'Have you experienced any sudden changes in vision?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Double vision': {
  questions: [
    {
      question: 'When did you start experiencing double vision?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the double vision constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Have you had any recent head injuries or changes in your vision?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Temporary blindness': {
  questions: [
    {
      question: 'When did the temporary blindness occur?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'How long did the blindness last?',
      options: ['Less than 1 hour', '1-2 hours', 'More than 2 hours'],
    },
    {
      question: 'Did you experience any other symptoms like headache or dizziness?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Asymmetric pupils': {
  questions: [
    {
      question: 'When did you first notice the asymmetry in your pupils?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Have you experienced any recent head trauma or changes in vision?',
      options: ['Yes', 'No', "Don't know"],
    },
    {
      question: 'Is the asymmetry constant or does it vary?',
      options: ['Constant', 'Varies'],
    },
  ],
},

'Swelling around both eyes': {
  questions: [
    {
      question: 'When did the swelling start?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the swelling constant or does it come and go?',
      options: ['Constant', 'Comes and goes'],
    },
    {
      question: 'Have you experienced any redness or pain in the area?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Drooping eyelids': {
  questions: [
    {
      question: 'When did you first notice the drooping?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the drooping in one eye or both?',
      options: ['One eye', 'Both eyes'],
    },
    {
      question: 'Have you experienced any other symptoms like difficulty moving the eyelid?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Eye flashes': {
  questions: [
    {
      question: 'When did you start seeing eye flashes?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Are the flashes constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Have you experienced any changes in vision or headaches?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Light sensitivity': {
  questions: [
    {
      question: 'When did you start experiencing light sensitivity?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the sensitivity to all types of light or specific types?',
      options: ['All types', 'Specific types'],
    },
    {
      question: 'Have you experienced any eye pain or redness?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Blurred vision': {
  questions: [
    {
      question: 'When did you first notice blurred vision?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the blurred vision constant or does it come and go?',
      options: ['Constant', 'Comes and goes'],
    },
    {
      question: 'Have you had any recent changes in your vision or eye injury?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Eye irritation': {
  questions: [
    {
      question: 'When did you start experiencing eye irritation?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the irritation constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Have you been exposed to any chemicals or allergens recently?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Dilated pupils': {
  questions: [
    {
      question: 'When did you notice the dilated pupils?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Have you experienced any other symptoms like headache or vision changes?',
      options: ['Yes', 'No', "Don't know"],
    },
    {
      question: 'Have you used any new medications or drugs recently?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},
'Painful nose': {
  questions: [
    {
      question: 'When did you start experiencing pain in your nose?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the pain constant or does it come and go?',
      options: ['Constant', 'Comes and goes'],
    },
    {
      question: 'Have you had any recent injuries or infections?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Swollen nose': {
  questions: [
    {
      question: 'When did the swelling start?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the swelling constant or does it fluctuate?',
      options: ['Constant', 'Fluctuates'],
    },
    {
      question: 'Have you experienced any pain or redness?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Runny nose': {
  questions: [
    {
      question: 'When did you start having a runny nose?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the runny nose constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Are there any other symptoms like congestion or sneezing?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Blocked nose': {
  questions: [
    {
      question: 'When did you first notice nasal blockage?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the blockage constant or does it come and go?',
      options: ['Constant', 'Comes and goes'],
    },
    {
      question: 'Have you experienced any pain or pressure in the nasal area?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Itchy nose or throat': {
  questions: [
    {
      question: 'When did you start feeling itchy?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the itchiness in your nose, throat, or both?',
      options: ['Nose', 'Throat', 'Both'],
    },
    {
      question: 'Have you been exposed to any known allergens?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Sneezing': {
  questions: [
    {
      question: 'When did you start sneezing?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the sneezing frequent or occasional?',
      options: ['Frequent', 'Occasional'],
    },
    {
      question: 'Are there any other symptoms like a runny nose or itching?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Bleeding from nose': {
  questions: [
    {
      question: 'When did the nosebleed start?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'How severe is the bleeding?',
      options: ['Light', 'Moderate', 'Heavy'],
    },
    {
      question: 'Have you experienced any recent trauma or high blood pressure?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Nose injury': {
  questions: [
    {
      question: 'When did the injury occur?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is there any pain or swelling associated with the injury?',
      options: ['Yes', 'No', "Don't know"],
    },
    {
      question: 'Have you experienced any bleeding or bruising?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Earache': {
  questions: [
    {
      question: 'When did you start experiencing earache?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the pain constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Have you had any recent infections or injuries?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Decreased hearing': {
  questions: [
    {
      question: 'When did you notice a decrease in hearing?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the hearing loss in one ear or both?',
      options: ['One ear', 'Both ears'],
    },
    {
      question: 'Have you experienced any other symptoms like pain or discharge?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Discharge from ear': {
  questions: [
    {
      question: 'When did you start noticing discharge from your ear?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'What does the discharge look like?',
      options: ['Clear', 'Yellow/green', 'Bloody'],
    },
    {
      question: 'Have you experienced any pain or decreased hearing?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Clogged ear': {
  questions: [
    {
      question: 'When did you first notice your ear feeling clogged?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the sensation constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Have you had any recent exposure to water or changes in altitude?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Itchy inner ear': {
  questions: [
    {
      question: 'When did you start feeling itchiness in your inner ear?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the itching constant or does it come and go?',
      options: ['Constant', 'Comes and goes'],
    },
    {
      question: 'Have you had any recent infections or allergies?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Ringing in ears': {
  questions: [
    {
      question: 'When did you start hearing ringing in your ears?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the ringing constant or intermittent?',
      options: ['Constant', 'Intermittent'],
    },
    {
      question: 'Have you been exposed to loud noises recently?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Ear injury': {
  questions: [
    {
      question: 'When did the ear injury occur?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is there any pain, swelling, or bleeding associated with the injury?',
      options: ['Yes', 'No', "Don't know"],
    },
    {
      question: 'Have you experienced any hearing loss or discharge?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Fluid from ear': {
  questions: [
    {
      question: 'When did you first notice fluid coming from your ear?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'What does the fluid look like?',
      options: ['Clear', 'Yellow/green', 'Bloody'],
    },
    {
      question: 'Have you had any pain or decreased hearing?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

'Ear rubbing': {
  questions: [
    {
      question: 'When did you start rubbing your ear?',
      options: ['Less than 24 hours ago', '1-2 days ago', 'More than 2 days ago'],
    },
    {
      question: 'Is the rubbing associated with any pain or itching?',
      options: ['Yes', 'No', "Don't know"],
    },
    {
      question: 'Have you experienced any other ear symptoms like discharge or hearing loss?',
      options: ['Yes', 'No', "Don't know"],
    },
  ],
},

  };
  