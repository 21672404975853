import React, { useState, forwardRef,useImperativeHandle,useRef,useEffect}  from 'react';
import TermsOfService from '../Components/TermsOfService';
import HealthBackground from '../Components/HealthBackground';
import SymptomSelection from '../Components/SymptomsSelection';
import SymptomMultiSelect from '../Components/SymptomMultiSelect';
import ProgressBar from '../Components/ProgressBar';
import './SymptomChecklist.css'
import ReportSummary from '../Components/ReportSummary';
import { FaExclamationCircle,FaArrowUp } from 'react-icons/fa';

const SymptomChecklist = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
      background: {},
      selectedBodyParts: [],
      selectedSymptoms: {},
    });
    const [showScrollTop, setShowScrollTop] = useState(false);

    // Reset the form and step to start fresh from TermsOfService
  const resetForm = () => {
    setFormData({
      background: {},
      selectedBodyParts: [],
      selectedSymptoms: {},
    });
    setCurrentStep(1);
  };

  
    const handleNext = () => setCurrentStep(currentStep + 1);
    const handleBack = () => setCurrentStep(currentStep - 1);
  
    const handleFormDataChange = (section, data) => {
      setFormData({ ...formData, [section]: data });
    };

    // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Show or hide the "Back to Top" button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {  
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const totalSteps = 4;
  
    return (
      <div className="symptomChecklist">
        <div className="symptom-header">
        <h1>Symptom Checklist</h1>
        </div>
        <div className="introduction">
        <p>The Symptom checklist helps your doctor better understand your symptoms but cannot diagnose them. Please download the checklist and bring it to your GP. Completing each question and its usually takes about 2-3 minutes.</p>
        <SymptomChecklistInstructions />
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        </div>

        {/* Progress Bar */}
      

        {currentStep === 1 && <TermsOfService onNext={handleNext} />}
  
        {currentStep === 2 && (
          <HealthBackground
            onNext={handleNext}
            onBack={handleBack}
            onChange={(data) => handleFormDataChange('background', data)}
            formData={formData.background}
          />
        )}
  
        {currentStep === 3 && (
          <SymptomSelection
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            onChange={(data) => handleFormDataChange('selectedBodyParts', data.selectedBodyParts)}
          />
        )}
  
  {currentStep === 4 && (
        <SymptomMultiSelect
            onNext={handleNext}
            onBack={handleBack}
            selectedBodyParts={formData.selectedBodyParts}  // Passing the selected body parts
            formData={formData.selectedSymptoms}            // Pass previously selected symptoms
            onChange={(data) => handleFormDataChange('selectedSymptoms', data)} // Update symptoms in formData
        />
    )}
  
        {currentStep === 5 && (
          <ReportSummary
            formData={formData}     
            onBack={handleBack}     
            resetForm={resetForm} 
          />
        )}
        {showScrollTop && (
        <button className="scroll-top-button" onClick={scrollToTop}>
          <FaArrowUp size={30} />
        </button>
      )}
      </div>
      
    );
  };

  export default SymptomChecklist;
  const SymptomChecklistInstructions = () => {
    return (
      <div className="instructions-container">
        <ol>
          <li>
            <strong>1. Finish in Two Minutes ⏱️</strong><br />
            No typing needed,just <strong>select options </strong>to complete the checklist quickly.
          </li>
          <li>
            <strong>2. Select and Deselect 👆</strong><br />
            <strong>Click to choose an answer. Click again </strong>to undo your choice.
          </li>
          <li>
            <strong>3. Sensitive Questions "!" 🚨</strong><br />
            Questions with an <FaExclamationCircle size="1em" color='blue' /> icon are important. Click to see why.
          </li>
          <li>
          <strong>4. Translate Your Report 🌍</strong><br />
            After finishing, <strong>click the language selector </strong>to translate. Only Support languages: ps, ar, my, ur.
          </li>
          <li>
            <strong>5. Download Your Report 📄</strong><br />
            <strong>Download the report</strong> to share with your GP for better understanding.
          </li>
        </ol>
      </div>
    );
  };
  