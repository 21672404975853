import React, { useState } from 'react';
import BackgroundImage from '../Pictures/medication.png'; 
import { FaExclamationCircle } from 'react-icons/fa'; 

const HealthBackground = ({ onNext, onBack, onChange, formData }) => {
  const [answers, setAnswers] = useState({
    'Where have you been in the last 3 months?': formData['Where have you been in the last 3 months?'] || [],
    'How old are you?': formData['How old are you?'] || '',
    'High Cholesterol?': formData['High Cholesterol?'] || '',
    'Recent Injury?': formData['Recent Injury?'] || '',
    'Smoking Cigarettes?': formData['Smoking Cigarettes?'] || '',
    'Family predisposition to allergy?': formData['Family predisposition to allergy?'] || '',
    'Pregnancy?': formData['Pregnancy?'] || '',
    'Overweight or Obesity?': formData['Overweight or Obesity?'] || '',
    'Diagnosed hypertension?': formData['Diagnosed hypertension?'] || '',
  });

  const [modalContent, setModalContent] = useState(null);  // For showing modal content
  const [showError, setShowError] = useState(false);  // To control error message visibility
  const [shake, setShake] = useState(false);  // To control shake effect

  // Handle input change for single-select fields, allow unselect
  const handleInputChange = (key, value) => {
    const updatedAnswers = { ...answers, [key]: answers[key] === value ? '' : value }; // Unselect logic
    setAnswers(updatedAnswers);
    onChange(updatedAnswers);  // Update parent component
  };

  // Handle multi-select (for countries visited)
  const handleMultiSelectChange = (country) => {
    const updatedCountries = answers['Where have you been in the last 3 months?'].includes(country)
      ? answers['Where have you been in the last 3 months?'].filter(item => item !== country)
      : [...answers['Where have you been in the last 3 months?'], country];

    handleInputChange('Where have you been in the last 3 months?', updatedCountries);
  };

  // Show modal for additional information
  const showModal = (infoType) => {
    switch (infoType) {
      case 'High Cholesterol?':
        setModalContent(
            'A cholesterol level over 190 mg/dl (5.0 mmol/l) or LDL fraction above 115 mg/dl (3.0 mmol/l) is generally considered high. However, for individuals with risk factors like diabetes, kidney disease, high blood pressure, or smoking, even lower levels may be deemed elevated.'
        );
        break;
      case 'Recent Injury?':
        setModalContent(
          'A recent injury refers to physical trauma sustained within the past few hours or days, such as a cut, burn, sprain, or broken bone, typically caused by actions like moving, falling, slipping, or contact with an object.'
        );
        break;
      case 'Smoking Cigarettes?':
        setModalContent(
          'If you currently Smokes and has a history of smoking for at least 10 years'
        );
        break;
      case 'Family predisposition to allergy?':
        setModalContent(
          'Whether close family member has an allergic disease, such as allergic rhinitis, asthma, atopic dermatitis, food allergy or allergic contact dermatitis'
        );
        break;
      case 'Pregnancy?':
        setModalContent(
          'Pregnancy can affect your overall health status and medical treatment options. If you are pregnant, some medications or procedures ' +
          'may need to be adjusted to ensure the safety of both the mother and the developing baby. Regular prenatal care is essential.'
        );
        break;
      case 'Overweight or Obesity?':
        setModalContent(
          'Obesity and overweight which can be unhealthy are defined by BMI, with obesity at 30 or higher and overweight between 25 and 29.9. These measurements may not be accurate for children, pregnant women, or some cultural groups, and for teens, BMI percentiles are used.'
        );
        break;
      case 'Diagnosed hypertension?':
        setModalContent(
          'Diagnosed hypertension excludes self-measured high blood pressure.'
        );
        break;
      case 'Where have you been in the last 3 months?':
        setModalContent(
          'Traveling to certain regions can expose you to different health risks, such as diseases that are more common in specific areas. ' +
          'For example, malaria or yellow fever are endemic in certain parts of the world. It\'s important to stay informed about necessary ' +
          'vaccinations and health precautions before traveling.'
        );
        break;
      default:
        setModalContent(null);
    }
  };

  // Check if all required fields are valid (including multi-select and single-select fields)
  const checkValidity = () => {
    const requiredFields = [
      'How old are you?',
      'High Cholesterol?',
      'Recent Injury?',
      'Smoking Cigarettes?',
      'Family predisposition to allergy?',
      'Pregnancy?',
      'Overweight or Obesity?',
      'Diagnosed hypertension?'
    ];
    const allSingleSelectValid = requiredFields.every((field) => answers[field] !== '');
    const isMultiSelectValid = answers['Where have you been in the last 3 months?'].length > 0;  // At least one country should be selected
    return allSingleSelectValid && isMultiSelectValid;
  };

  // Handle the "Next" button click
  const handleNext = () => {
    if (checkValidity()) {
      setShowError(false);
      onNext();  // Proceed to the next step
    } else {
      setShowError(true);
      setShake(true);
      setTimeout(() => setShake(false), 500);  // Stop shaking after 0.5s
    }
  };

  // Reusable function for Yes/No/Don't Know questions
  const renderYesNoDontKnow = (questionKey, label) => (
    <div className={`question ${shake && answers[questionKey] === '' ? 'shake' : ''}`}>
      <p className="question-label">
        {label} <FaExclamationCircle className="info-icon" onClick={() => showModal(questionKey)} />
      </p>
      <div className="btn-group">
        <button className={answers[questionKey] === 'Yes' ? 'selected' : ''} onClick={() => handleInputChange(questionKey, 'Yes')}>Yes</button>
        <button className={answers[questionKey] === 'No' ? 'selected' : ''} onClick={() => handleInputChange(questionKey, 'No')}>No</button>
        <button className={answers[questionKey] === "Don't know" ? 'selected' : ''} onClick={() => handleInputChange(questionKey, "Don't know" )}>Don't Know</button>
      </div>
      {showError && answers[questionKey] === '' && <p className="error-message">Please select one answer</p>}
    </div>
  );

  return (
    <div className="symptom-container">
      {/* Modal for More Info */}
      {modalContent && (
        <div className="symptom-modal-overlay">
          <div className="symptom-modal">
            <div className="symptom-modal-header">
              <h3>Why am I being asked this?</h3>
              <button className="close-btn" onClick={() => setModalContent(null)}>×</button>
            </div>
            <div className="symptom-modal-content">
              <p>{modalContent}</p>
            </div>
          </div>
        </div>
      )}

      <div className="symptom-content">
        <div className="symptom-text">
          <h2>Health Background</h2>
          <div className="section-header">
            <span className="section-icon">✔️</span>
            <span>Select all that apply</span>
            <hr />
          </div>

          {/* Multi-Select: Where have you been in the last 3 months */}
          <div className={`question multi-select ${shake && answers['Where have you been in the last 3 months?'].length === 0 ? 'shake' : ''}`}>
            <p className="question-label">
              <FaExclamationCircle className="info-icon" onClick={() => showModal('Where have you been in the last 3 months?')} />Where have you been in the last 3 months?
            </p>
            <div className="checkbox-group">
              {['Australia', 'Asian (Excluding Middle East)', 'Africa', 'Middle East', 'Europe', 'American'].map(country => (
                <button
                  key={country}
                  className={answers['Where have you been in the last 3 months?'].includes(country) ? 'selected' : ''}
                  onClick={() => handleMultiSelectChange(country)}
                >
                  {country}
                </button>
              ))}
            </div>
            {showError && answers['Where have you been in the last 3 months?'].length === 0 && <p className="error-message">Please select at least one option</p>}
          </div>

          <div className="section-header">
            <span className="section-icon">✔️</span>
            <span>Select one answer for each</span>
            <hr />
          </div>

          {/* Single-Select: How old are you? */}
          <div className={`question ${shake && answers['How old are you?'] === '' ? 'shake' : ''}`}>
            <p className="question-label">How old are you?</p>
            <div className="btn-group">
              <button className={answers['How old are you?'] === '0-18' ? 'selected' : ''} onClick={() => handleInputChange('How old are you?', '0-18')}>0-18</button>
              <button className={answers['How old are you?'] === '18-30' ? 'selected' : ''} onClick={() => handleInputChange('How old are you?', '18-30')}>18-30</button>
              <button className={answers['How old are you?'] === '31-60' ? 'selected' : ''} onClick={() => handleInputChange('How old are you?', '31-60')}>31-60</button>
              <button className={answers['How old are you?'] === '61-120' ? 'selected' : ''} onClick={() => handleInputChange('How old are you?', '61-120')}>61-120</button>
            </div>
            {showError && answers['How old are you?'] === '' && <p className="error-message">Please select one answer</p>}
          </div>

          {/* Render Yes/No/Don't Know questions */}
          {renderYesNoDontKnow('High Cholesterol?', 'High Cholesterol?')}
          {renderYesNoDontKnow('Recent Injury?', 'Recent Injury?')}
          {renderYesNoDontKnow('Smoking Cigarettes?', 'Smoking Cigarettes?')}
          {renderYesNoDontKnow('Family predisposition to allergy?', 'Family predisposition to allergy?')}
          {renderYesNoDontKnow('Pregnancy?', 'Pregnancy?')}
          {renderYesNoDontKnow('Overweight or Obesity?', 'Overweight or Obesity?')}
          {renderYesNoDontKnow('Diagnosed hypertension?', 'Diagnosed hypertension?')}

          {/* Back and Next buttons */}
          <div className="button-container">
            <button className="btn back" onClick={onBack}>Back</button>
            <button className="btn next" onClick={handleNext}>Next</button>
          </div>
        </div>

        {/* Centered Image */}
        <div className="health-image">
          <img src={BackgroundImage} alt="Health Background" />
        </div>
      </div>
    </div>
  );
};

export default HealthBackground;
