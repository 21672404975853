import React from 'react';
import './FlowChart.css';
import medicare from '../Pictures/medicare.png'
import Knowledge from '../Pictures/knowledge.png'
import Healthcare from '../Pictures/medical-service.png'
import bill from '../Pictures/bulkbill.png'
import checklist from '../Pictures/checklist.png'
import appointment from '../Pictures/appointment.png'
import emergency from '../Pictures/emergency.png'
import clinic from '../Pictures/clinic.png'
import { useNavigate } from 'react-router-dom';
import {
  MdArrowForward,
  MdArrowDownward,
  MdArrowBack,
} from 'react-icons/md';

const FlowChart = ({ scrollToSection }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleGpLocatorClick = () => {
    navigate('/gp-locator'); 
  };
  return (
    <div className="flowchart-container">
      <div className="flowchart-row">
        {/* Medical Terms */}
        <div className="flowchart-item" onClick={() => scrollToSection('glossary')}>
        <img src={Knowledge} alt="Knowledge" style={{ width: '70px', height: '60px' }} />
          <p>Understand key medical terms.</p>
        </div>
        <MdArrowForward size={40} className="arrow" />
        
        {/* Healthcare Services */}
        <div className="flowchart-item" onClick={() => scrollToSection('healthService')}>
        <img src={Healthcare} alt="Healthcare" style={{ width: '60px', height: '60px' }} />
          <p>Explore available healthcare services.</p>
        </div>
        <MdArrowForward size={40} className="arrow" />
        
        {/* Medicare */}
        <div className="flowchart-item" onClick={() => scrollToSection('medicare')}>
          <img src={medicare} alt="Medicare" style={{ width: '70px', height: '50px' }} />
          <p>Discover Medicare benefits.</p>
        </div>

        <MdArrowForward size={40} className="arrow" />

        {/* Bulk Billing */}
        <div className="flowchart-item" onClick={() => scrollToSection('bulkBilling')}>
        <img src={bill} alt="Bul-bill" style={{ width: '70px', height: '60px' }} />
          <p>Learn about bulk billing.</p>
        <MdArrowDownward size={40} className="arrow" />
        </div>

       

      </div>

      
  
      <div className="flowchart-row">
       {/* GP Locator */}
       <div className="flowchart-item" onClick={handleGpLocatorClick}>
        <img src={clinic} alt="Clinic" style={{ width: '72px', height: '60px' }} />
          <p>Use GP Locator for clinics.</p>
        </div>  
        <MdArrowBack size={40} className="arrow" />

        {/* Emergency Contacts */}
        <div className="flowchart-item" onClick={() => scrollToSection('emergencyContacts')}>
        <img src={emergency} alt="Appointment" style={{ width: '72px', height: '60px' }} />
          <p>Find emergency contacts.</p>
        </div>

        <MdArrowBack size={40} className="arrow" />

         {/* Book GP */}
         <div className="flowchart-item" onClick={() => scrollToSection('bookGP')}>
        <img src={appointment} alt="Appointment" style={{ width: '72px', height: '60px' }} />
          <p>Book a GP appointment.</p>
         
        </div>

        <MdArrowBack size={40} className="arrow" />
        
        {/* GP Checklist */}
        <div className="flowchart-item" onClick={() => scrollToSection('prepareGP')}>
        <img src={checklist} alt="Checklist" style={{ width: '60px', height: '60px' }} />
          <p>Prepare for your GP visit.</p>
        </div>  
      </div>
    </div>
  );
  
  
};

export default FlowChart;
