import React from 'react';
import { createRoot } from 'react-dom/client'; // Correctly import createRoot
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

// First, get the container element from the DOM
const container = document.getElementById('root');

// Create a root using createRoot method
const root = createRoot(container);

// Use the root to render your application wrapped in BrowserRouter
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


