import React, { useState } from 'react';
import TermCard from './TermCard';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import './TermCarousel.css';
import bill from '../Pictures/bulkbill.png';
import medicare from '../Pictures/medicare.png';
import GP from '../Pictures/gp.png';
import appointment from '../Pictures/appointment.png'
import reaction from '../Pictures/reaction.png'
import vaccination from '../Pictures/vaccination.png'
import Diagnosis from '../Pictures/diagnosis.png'
import Confidentiality from '../Pictures/confidentiality.png'
import pharmacy from '../Pictures/pharmacy.png'
import medicine from '../Pictures/medicine.png'
import otc from '../Pictures/otc.png'
import medicalHistory from '../Pictures/medical-history.png'
import medication from '../Pictures/medication.png'
import symptoms from '../Pictures/symptoms.png'


import outOfPocket from '../Pictures/out-of-pocket.png'
const TermCarousel = () => {
  const terms = [
    { term: 'GP (General Practitioner)', description: 'Primary health provider you see for check-ups and non-emergency illnesses.', imageSrc: GP},
    { term: 'Appointment', description: 'A scheduled meeting with a GP or specialist; important to arrive on time or cancel if unable.', imageSrc: appointment },
    { term: 'Medicare Card', description: 'Card providing access to free or discounted medical services in Australia.', imageSrc: medicare},
    { term: 'Immunisation', description: 'Vaccinations to prevent diseases, using the body’s natural defenses to build resistance.', imageSrc: vaccination },
    { term: 'Adverse Reaction', description: 'Reactions like headache or rash from medication; notify your GP if experienced.', imageSrc: reaction },
    { term: 'Diagnosis', description: 'The identification of an illness based on symptoms and assessment.', imageSrc: Diagnosis },
    { term: 'Confidentiality', description: 'Assurance that your health information is private and shared only with consent.', imageSrc: Confidentiality },
    { term: 'Medicare Gap', description: 'The amount you pay out-of-pocket for medical services beyond what Medicare covers.', imageSrc: outOfPocket },
    { term: 'Bulk Billing', description: 'Service where the GP charges the government directly, making the visit free or reduced for you.', imageSrc: bill },
    { term: 'Chemist/Pharmacy', description: 'A place to buy medications and seek advice on minor health issues, some over-the-counter.', imageSrc: pharmacy },
    { term: 'Generic Medicine', description: 'Cheaper medications equivalent to brand name, meeting the same safety standards.', imageSrc: medicine },
    { term: 'Over-the-Counter Medication', description: 'Medications you can buy without a prescription for minor health issues.', imageSrc: otc },
    { term: 'Medical History', description: 'Past health information used to assist in diagnosis and treatment planning.', imageSrc: medicalHistory },
    { term: 'Medication', description: 'Drugs prescribed or available over-the-counter to treat various conditions.', imageSrc: medication },
    { term: 'Symptom', description: 'Signs indicating an illness or health issue.', imageSrc: symptoms },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [flippedStates, setFlippedStates] = useState(Array(terms.length).fill(false));

  const nextTerm = () => {
    setCurrentIndex((currentIndex + 1) % terms.length);
  };

  const prevTerm = () => {
    setCurrentIndex((currentIndex - 1 + terms.length) % terms.length);
  };

  const toggleFlip = () => {
    const newFlippedStates = [...flippedStates];
    newFlippedStates[currentIndex] = !newFlippedStates[currentIndex];
    setFlippedStates(newFlippedStates);
  };

  return (
    <div className="term-carousel">
      <p>Here are some important medical terms may encounter. Click on a card to flip it and reveal its meaning.</p>
      <div className="carousel-content">
        <FaAngleLeft className="arrow" onClick={prevTerm} />
        <TermCard
          {...terms[currentIndex]}
          flipped={flippedStates[currentIndex]}
          onFlip={toggleFlip}
        />
        <FaAngleRight className="arrow" onClick={nextTerm} />
      </div>
      <p>{currentIndex + 1}/{terms.length}</p>
    </div>
  );
};

export default TermCarousel;
