import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { FaUserDoctor,FaClipboardList } from 'react-icons/fa6';
import { FaArrowRight } from 'react-icons/fa';
import { GiGiftOfKnowledge } from "react-icons/gi";
import { MdLanguage } from "react-icons/md";
import { IoBarChartSharp } from "react-icons/io5";
import doctor from '../Pictures/doctor.jpg'; 
import map from '../Pictures/map.png';
import language from '../Pictures/language.png';
import healthcareKnowledge from '../Pictures/healthcareKnowledge.png';
import barChart from '../Pictures/barChart.jpeg';
import symptomChecklist from '../Pictures/symptomChecklist.png';
import gpLocator from '../Pictures/gpLocator.png';

function Home() {
  const [selectedStep, setSelectedStep] = useState(1);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToGuide = () => {
    const guideSection = document.querySelector('.easy-guide-container');
    guideSection.scrollIntoView({ behavior: 'smooth' });
  };
  

  const stepsData = [
    {
      id: 1,
      icon: <MdLanguage/>,
      title: 'Step 1: Select a Language',
      image: language,
      contentTitle:'Select your preferred Language',
      description: 'Select your native language at the top for easier website navigation.',
    },
    {
      id: 2,
      icon: <GiGiftOfKnowledge />,
      title: 'Step 2: Learn about Healthcare',
      image: healthcareKnowledge,
      contentTitle:'Learn Victoria Healthcare with the MediSupport Hub',
      description: 'Explore more Victoria healthcare info in MediSupport Hub, including Medicare benefits and how to book a GP.',
      link: '/mediSupport-hub',
    },
    {
      id: 3,
      icon: <IoBarChartSharp />,
      title: 'Step 3: Explore Women Diseases ',
      image: barChart,
      contentTitle:"Women's Health Insights from 5 Migrant Countries",
      description: 'Get insights for common women diseases at top 5 migrant countries into Australia.',
      link: '/insight',
    },
    {
      id: 4,
      icon: <FaClipboardList />,
      title: 'Step 4: Check your Symptom ',
      image: symptomChecklist,
      contentTitle:"Share Your Symptoms with Your Doctor",
      description: 'Answer quick health questions in 3 minutes to help your doctor identify the issue.',
      link: '/symptom-checklist',
    },
    {
      id: 5,
      icon: <FaUserDoctor />,
      title: 'Step 5: Find a Clinic',
      image: gpLocator,
      contentTitle:"Find the Nearest Clinics",
      description: 'Find and explore a near clinic information and address ',
      link: '/gp-locator',
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };

    const showContent = () => {
      const guideSection = document.querySelector('.easy-guide-container');
      const servicesSection = document.querySelector('.recommended-services');

      const guidePosition = guideSection.getBoundingClientRect().top;
      const servicesPosition = servicesSection.getBoundingClientRect().top;

      if (guidePosition <= window.innerHeight) {
        setShowGuide(true); // Set guide section visible
      }
      if (servicesPosition <= window.innerHeight) {
        setShowServices(true); // Set services section visible
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', showContent);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', showContent);
    };
  }, []);

  return (
    <div className="home-page-container">
      <div className="home-overlay">
        <div className="home-cover">
          <h1>Empowering Refugee Migrant Women with Accessible Healthcare in Australia</h1>
          <p>Our website provides information about affordable care and multilingual support to help refugee and migrant women overcome barriers to better health.</p>
          <button onClick={scrollToGuide} className="learn-more-btn">Learn More</button>
        </div>
      </div>

      {/* Easy Guide Section */}
      <div className={`easy-guide-container ${showGuide ? 'fade-in' : ''}`}>
        <h2 className="guide-title">Easy Guide to Learn Our Website</h2>
        <div className="guide-content">
          {/* Steps (Left side) */}
          <div className="steps-container">
            {stepsData.map((step) => (
              <div
                key={step.id}
                className={`step-button ${selectedStep === step.id ? 'selected' : ''}`}
                onClick={() => setSelectedStep(step.id)}
              >
                <span className="step-icon">{step.icon}</span>
                <span className="step-title">{step.title}</span>
                {selectedStep === step.id && <FaArrowRight  className="step-arrow" />}
              </div>
            ))}
          </div>

          {/* Content (Right side) */}
          <div className="guide-content-container">
            <img
              src={stepsData[selectedStep - 1].image}
              alt={stepsData[selectedStep - 1].contentTitle}
              className="guide-content-image"
            />
            <div className="guide-content-text">
              <h3>{stepsData[selectedStep - 1].contentTitle}</h3>
              <p>{stepsData[selectedStep - 1].description}</p>
              <Link to={stepsData[selectedStep - 1].link} className="learn-more">
                Learn More →
              </Link>
            </div>
          </div>
        </div>
      </div>
      <RecommendedServices />

      {showScrollTop && (
        <button onClick={scrollToTop} className="scroll-top-btn">
          ↑
        </button>
      )}
    </div>
  );
}

export default Home;

function RecommendedServices({ showServices }) {
  const servicesData = [
    {
      id: 1,
      title: 'Use Symptom checklist to Check your Symptoms ',
      description:
        'Worried about a language barrier or difficulty explaining your symptoms? Use our Symptom Checklist to help identify and define them. Translate it into your preferred language, print it, and share with your doctor for a clearer diagnosis.',
      image: doctor,
      buttonText: 'Start Symptom Checklist',
      buttonLink: '/symptom-checklist',
    },
    {
      id: 2,
      title: 'Navigating Healthcare with the GP Locator',
      description:
        'Struggling to find affordable and nearest clinics? Our GP Locator tool helps migrant women navigate the healthcare system. The map highlights clinics information, providing insights into clinic details and Medicare coverage.',
      image: map,
      buttonText: 'Start GP Locator',
      buttonLink: '/gp-locator',
    },
  ];

  return (
    <div className={`recommended-services ${showServices ? 'fade-in' : ''}`}>
      <h2 className="recommended-section-title">Recommended Services</h2>
      {servicesData.map((service, index) => (
        <div key={service.id} className={`recommended-service-item ${index % 2 === 0 ? 'image-left' : 'image-right'}`}>
          <img src={service.image} alt={service.title} className="recommended-service-image" />
          <div className="recommended-service-content">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <Link to={service.buttonLink} className="recommended-service-btn">
              {service.buttonText}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

