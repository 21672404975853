import React, { useState } from 'react';
import Confidentiality from '../Pictures/confidentiality.png';

const TermsOfService = ({ onNext }) => {
  const [isChecked, setIsChecked] = useState(false); // State to track checkbox
  const [error, setError] = useState(''); // State for error message

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked); // Update isChecked based on checkbox status
    if (e.target.checked) {
      setError(''); // Clear error if checkbox is checked
    }
  };

  const handleNext = () => {
    if (isChecked) {
      onNext(); // Proceed to the next step if the checkbox is checked
    } else {
      setError('You must agree to the Terms of Service before proceeding.'); // Show error message
    }
  };

  return (
    <div className="symptom-container">
      <div className="symptom-content">
        <div className="symptom-text">
          <p>Please keep the following terms in mind when using our symptom checklist:</p>
          <ul>
            <li>This <strong>cannot diagnose</strong>, just reply some question and tell your gp your siuation.</li>
            <li>This questionnaire is only <strong>designed by women</strong>.</li>
            <li>Your data is <strong>protected</strong> and won’t be shared.</li>
            <li>
              <strong>In emergencies, do not use this tool.</strong> Call triple <strong>zero (000) </strong>immediately if you experience:
              <ul className="emergency-list">
                <li>Uncontrollable bleeding</li>
                <li>Breathing difficulties</li>
                <li>Confusion or unusual behaviour</li>
                <li>Chest pain lasting 2+ minutes</li>
                <li>Choking</li>
                <li>Vomiting or coughing blood</li>
                <li>Sudden inability to move</li>
                <li>Severe accidents</li>
              </ul>
            </li>
          </ul>

          {/* Checkbox */}
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange} // Update state on change
            />
            I have read and accept the <a href="#">Terms of Service</a>.
          </label>

          {/* Error Message */}
          {error && <p className="error-message">{error}</p>}

          {/* Button */}
          <button
            className="btn large pink-gradient"
            onClick={handleNext}
          >
            Let's Start
          </button>
        </div>

        {/* Image */}
        <div className="terms-image">
          <img src={Confidentiality} alt="Confidentiality" />
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
