import React, { useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { FaBars } from 'react-icons/fa'; // Import the necessary icon
import './Navbar.css';
import logo from '../Pictures/logo.png';
import { GrLanguage } from "react-icons/gr";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Track if it's mobile view

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Update isMobileView based on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Initialize Google Translate element
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,ps,ar,my,ur',
      },
      'google_translate_element'
    );
  };

  // Load the Google Translate script
  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div className="navbar sticky-top navbar-light bg-light">
      {/* Pink bar with Google Translate widget */}
      <div className="pink-bar">
        <GrLanguage size={25} />
        <div id="google_translate_element"></div>
      </div>

      {/* Navbar */}
      <header className="navbar ">
        <div className="navbar-container">
          <Link to="/" className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </Link>

          <button className="hamburger-icon" onClick={toggleMenu}>
            <FaBars /> {/* Hamburger icon for mobile view */}
          </button>

          <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
            <ul className="nav-links">
              <CustomLink to="/">Home</CustomLink>
              <CustomLink to="/mediSupport-hub">MediSupport Hub</CustomLink>
              <CustomLink to="/gp-locator">GP Locator</CustomLink>
              <CustomLink to="/symptom-checklist">Symptom Checklist</CustomLink>
              <CustomLink to="/insight">Medical Insight</CustomLink>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className={match ? 'active' : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
