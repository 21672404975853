import React, { useState } from 'react';
import Body from '../Pictures/body.jpg'; 

const bodyParts = [
  'Head', 'Eye', 'Nose', 'Ear', 'Mouth', 'Throat', 'Chest', 'Breast',
  'Upper Arm', 'Forearm', 'Hand', 'Upper Abdomen', 'Middle Abdomen',
  'Lower Abdomen', 'Sexual Organs', 'Thigh', 'Knee', 'Lower Leg', 'Foot', 'Back', 'Lower Back', 'Buttocks', 'Emotion', 'Skin', 'Others'
];

const SymptomSelection = ({ onNext, onBack, formData, onChange }) => {
  const [selectedBodyParts, setSelectedBodyParts] = useState(formData?.selectedBodyParts || []);
  const [error, setError] = useState({ bodyParts: false, tooMany: false });
  const [shake, setShake] = useState(false);

  const handleBodyPartSelect = (part) => {
    let updatedParts = selectedBodyParts.includes(part)
      ? selectedBodyParts.filter((p) => p !== part)
      : [...selectedBodyParts, part];

    // Handle the "too many" selection error
    if (updatedParts.length > 3) {
      setError({ ...error, tooMany: true });
      updatedParts = selectedBodyParts; // Prevent selecting more than 3
    } else {
      setError({ ...error, tooMany: false, bodyParts: false }); // Reset errors when valid
    }

    setSelectedBodyParts(updatedParts);
    onChange({ selectedBodyParts: updatedParts });
  };

  const handleNext = () => {
    if (selectedBodyParts.length === 0) {
      setError({ ...error, bodyParts: true });
      setShake(true);
      setTimeout(() => setShake(false), 500); // Remove shake effect after 0.5s
    } else {
      onNext(); // Proceed to next step
    }
  };

  return (
    <div className={`symptom-container ${shake ? 'shake' : ''}`}>
      <div className="symptom-content">
        <div className="symptom-text">
          <h2>Select Body Parts with Symptoms</h2>
          <div className="section-header">
            <span className="section-icon">✔️</span>
            <span>Select up to 3 body parts</span>
            <hr />
          </div>

          <div className="checkbox-group">
            {bodyParts.map((part) => (
              <button
                key={part}
                className={selectedBodyParts.includes(part) ? 'selected' : ''}
                onClick={() => handleBodyPartSelect(part)}
              >
                {part}
              </button>
            ))}
          </div>

          {/* Error Messages */}
          {error.bodyParts && <p className="error-message">Please select at least one body part</p>}
          {error.tooMany && <p className="error-message">You can only select up to 3 body parts</p>}

          {/* Buttons */}
          <div className="button-container">
            <button className="btn back" onClick={onBack}>Back</button>
            <button className="btn next" onClick={handleNext}>Next</button>
          </div>
        </div>

        <div className="body-image">
          <img src={Body} alt="Body Diagram" />
        </div>
      </div>
    </div>
  );
};

export default SymptomSelection;
