import { useRef } from 'react';

const useScrollSections = () => {
  const glossaryRef = useRef(null); // DOM ref for scrolling
  const glossaryAccordionRef = useRef(null); // Accordion component ref

  const healthServiceRef = useRef(null);
  const healthServiceAccordionRef = useRef(null);

  const medicareRef = useRef(null);
  const medicareAccordionRef = useRef(null);

  const bulkBillingRef = useRef(null);
  const bulkBillingAccordionRef = useRef(null);

  const prepareGPRef = useRef(null);
  const prepareGPAccordionRef = useRef(null);

  const bookGPRef = useRef(null);
  const bookGPAccordionRef = useRef(null);

  const emergencyContactsRef = useRef(null);
  const emergencyContactsAccordionRef = useRef(null);

  const scrollAndOpenAccordion = (section) => {
    let ref;
    let accordionRef;

    switch (section) {
      case 'glossary':
        ref = glossaryRef;
        accordionRef = glossaryAccordionRef;
        break;
      case 'healthService':
        ref = healthServiceRef;
        accordionRef = healthServiceAccordionRef;
        break;
      case 'medicare':
        ref = medicareRef;
        accordionRef = medicareAccordionRef;
        break;
      case 'bulkBilling':
        ref = bulkBillingRef;
        accordionRef = bulkBillingAccordionRef;
        break;
      case 'prepareGP':
        ref = prepareGPRef;
        accordionRef = prepareGPAccordionRef;
        break;
      case 'bookGP':
        ref = bookGPRef;
        accordionRef = bookGPAccordionRef;
        break;
      case 'emergencyContacts':
        ref = emergencyContactsRef;
        accordionRef = emergencyContactsAccordionRef;
        break;
      default:
        break;
    }

    if (ref && accordionRef) {
      // Open the accordion first
      accordionRef.current?.openAccordion();

      // Scroll to the section after a slight delay
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Adjust the delay to ensure smooth transition
    }
  };

  return {
    glossaryRef,
    glossaryAccordionRef,
    healthServiceRef,
    healthServiceAccordionRef,
    medicareRef,
    medicareAccordionRef,
    bulkBillingRef,
    bulkBillingAccordionRef,
    prepareGPRef,
    prepareGPAccordionRef,
    bookGPRef,
    bookGPAccordionRef,
    emergencyContactsRef,
    emergencyContactsAccordionRef,
    scrollAndOpenAccordion,
  };
};

export default useScrollSections;
