import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';  // Using Chart.js to generate charts
import Papa from 'papaparse';
import axios from 'axios';
import './Insight.css'; 

// Helper function to fetch and parse CSV data
const fetchData = async (file) => {
  const response = await axios.get(file);
  return Papa.parse(response.data, { header: true, dynamicTyping: true }).data;
};

const Insight = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('AFG');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('Y30T34');
  const [topCauses, setTopCauses] = useState([]);
  
  // References for chart canvases
  const barChartRef = useRef(null);
  const lineChartRef = useRef(null); // Adding a reference for the line chart
  let barChartInstance = useRef(null);
  let lineChartInstance = useRef(null); // Adding an instance for the line chart

  // Fetch CSV data and update state
  useEffect(() => {
    fetchData('/health_data.csv').then((resultData) => {
      setData(resultData);
      updateCharts(resultData);
    });
  }, []);

  // Update charts when country or age group changes
  useEffect(() => {
    updateCharts(data);
  }, [selectedCountry, selectedAgeGroup, data]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleAgeGroupChange = (event) => {
    setSelectedAgeGroup(event.target.value);
  };

  // Calculate average DALY rate by cause
  const avgDalyRateByCause = (data) => {
    const result = {};
    data.forEach((item) => {
      const key = item.Cause;
      if (!result[key]) result[key] = [];
      result[key].push(item['DALY rate per 100 000 population']);
    });
    return Object.entries(result).map(([key, values]) => ({
      Key: key,
      Average: values.reduce((a, b) => a + b, 0) / values.length,
    }));
  };

  // Calculate average DALY rate by year
  const avgDalyRateByYear = (data) => {
    const result = {};
    data.forEach((item) => {
      const key = `${item.Year} - ${item.Cause}`;
      if (!result[key]) result[key] = [];
      result[key].push(item['DALY rate per 100 000 population']);
    });
    return Object.entries(result).map(([key, values]) => ({
      Year: key.split(' - ')[0],  // Year
      Cause: key.split(' - ')[1],  // Cause
      Average: values.reduce((a, b) => a + b, 0) / values.length,
    }));
  };

  // Update charts
const updateCharts = (data) => {
  const filteredData = data.filter((item) => item.ISO3 === selectedCountry && item['Age Group'] === selectedAgeGroup);
  setFilteredData(filteredData);

  const barData = avgDalyRateByCause(filteredData);
  
  // Group data by year and cause for the line chart
  const lineData = filteredData.reduce((acc, curr) => {
    const year = curr.Year;
    const cause = curr.Cause;
    if (!acc[cause]) {
      acc[cause] = [];
    }
    acc[cause].push({ year, dalyRate: curr['DALY rate per 100 000 population'] });
    return acc;
  }, {});

  // Destroy existing barChartInstance if present
  if (barChartInstance.current) {
    barChartInstance.current.destroy();
  }

  // Create a new bar chart instance
  barChartInstance.current = new Chart(barChartRef.current, {
    type: 'bar',
    data: {
      labels: barData.map((item) => item.Key),
      datasets: [
        {
          label: 'Average DALY Rate',
          data: barData.map((item) => item.Average),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Average Life Lost Annually'
          }
        },
        x: {
          title: {
            display: true,
            text: 'Causes'
          }
        }
      },
    },
  });

  // Destroy existing lineChartInstance if present
  if (lineChartInstance.current) {
    lineChartInstance.current.destroy();
  }

  // Create datasets for each cause, with the DALY rate per year
  const datasets = Object.entries(lineData).map(([cause, data]) => ({
    label: cause, // Use cause as the label for the datasets
    data: data.map((d) => d.dalyRate), // DALY rate per year
    borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
    fill: false,
  }));

  // Create a new line chart instance with the causes as labels
  lineChartInstance.current = new Chart(lineChartRef.current, {
    type: 'line',
    data: {
      labels: [...new Set(filteredData.map((item) => item.Year))], // Unique years on the X-axis
      datasets: datasets,
    },
    options: {
      maintainAspectRatio: true, // Ensure the aspect ratio is maintained
      aspectRatio: 2.5, // Set a higher aspect ratio to make the chart narrower
      plugins: {
        title: {
          display: true,
          text: 'Average Life Lost Rate per 100,000 Population (2017-2021)' // Set the title here
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'DALY Rate per 100,000 Population'
          }
        },
        x: {
          title: {
            display: true,
            text: 'Year'
          }
        }
      },
    },
  });
  // Update the top 3 causes of death
  const causes = {};
  filteredData.forEach(item => {
    if (!causes[item.Cause]) causes[item.Cause] = [];
    causes[item.Cause].push(item['DALY rate per 100 000 population']);
  });

  const averageCauses = Object.entries(causes)
    .map(([cause, values]) => ({
      Cause: cause,
      Average: values.reduce((a, b) => a + b, 0) / values.length,
    }))
    .sort((a, b) => b.Average - a.Average)
    .slice(0, 3);

  setTopCauses(averageCauses);
};

  return (
    <div className="insight">
      <h1 className="insight-header">Top Causes of Deaths Among Top 5 Migrant Countries in Australia</h1>
      <p className="introduction">This dashboard shows the DALY rate per 100,000 population by cause, year, and age group for the top 5 migrant countries into Australia.</p>

      <div className="dropdown-container-country">
        <label>Select a country:</label>
        <select value={selectedCountry} onChange={handleCountryChange}>
          <option value="AFG">Afghanistan</option>
          <option value="IRQ">Iraq</option>
          <option value="MMR">Myanmar</option>
          <option value="PAK">Pakistan</option>
          <option value="SYR">Syria</option>
        </select>
      </div>

      <div className="dropdown-container-age">
        <label>Select an age group:</label>
        <select value={selectedAgeGroup} onChange={handleAgeGroupChange}>
          <option value="Y30T34">30-34</option>
          <option value="Y35T39">35-39</option>
          <option value="Y40T44">40-44</option>
          <option value="Y45T49">45-49</option>
        </select>
      </div>

      <div className="chart-wrapper">
        <div className="chart-container">
          <h2>Average Life Lost Per 100,000 Population</h2>
          <canvas ref={barChartRef} id="bar-chart"></canvas>
        </div>

        <div className="top-causes">
          <p className="top-cause-desc">Top 3 Causes of Death in 2021</p>
          <ul>
            {topCauses.map((cause, index) => (
              <li key={index} className={`top-cause top-cause-${index + 1}`}>
                {cause.Cause}: {Math.round(cause.Average)}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Newly added line chart */}
      <div className="chart-container">
        <h2>Average Life Lost Rate per 100,000 Population (2017-2021)</h2>
        <canvas ref={lineChartRef} id="line-chart"></canvas>
      </div>

      {/* Newly added description section */}
      <div className="description-section">
        <h2>Screening, tests and scans</h2>
        <p>What are Diagnostic Tests?</p>
        <ul>
          <li><b>a. Pathology Tests</b></li>
          <ul>
            <li>Blood tests</li>
            <li>Urine tests</li>
            <li>Biopsies</li>
          </ul>
          <li><b>b. Imaging tests or scans</b></li>
          <ul>
            <li>X-rays</li>
            <li>CT scans</li>
            <li>MRI scans</li>
            <li>PEI scans</li>
            <li>Ultrasound scans</li>
          </ul>
        </ul>

        <h3>In Australia, common screening tests program are:</h3>
        <ul>
          <li><b>BreastScreen Australia Program:</b> provides a free mammogram every 2 years to women aged 40 years and over</li>
          <li><b>National Cervical Screening Program:</b> provides a free cervical screening test every 5 years to women aged 25 to 74 years</li>
          <li><b>National Diabetes Services Scheme:</b> helps people with diabetes to understand and manage their life with diabetes</li>
        </ul>

        <h3>Does Medicare cover the cost of pathology tests?</h3>
        <p><b>Yes, it covers the cost of most pathology tests.</b> Book a GP appointment to arrange a health checkup now.</p>
      </div>
    </div>
  );
};

export default Insight;
