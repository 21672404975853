import React, { useState, forwardRef,useImperativeHandle,useRef,useEffect}  from 'react';
import './MediSupportHub.css';  
import FlowChart from '../Components/FlowChart'; 
import ContactCard from '../Components/ContactCard'; 
import TermCarousel from '../Components/TermCarousel';
import useScrollSections from '../Components/useScrollSections';
import booking from'../Pictures/booking.png';
import Checklist from '../Components/Checklist';
import HealthBenefit from '../Components/HealthBenefit';
import { FaArrowUp } from 'react-icons/fa'; 


const Accordion = forwardRef(({ title, children, isOpen, onToggle }, ref) => {
  const containerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openAccordion() {
      if (!isOpen) {
        onToggle();  
      }
    },
    containerRef,
  }));

  return (
    <div ref={containerRef} className="accordion-section">
      <button className={`accordion-title ${isOpen ? "open" : ""}`} onClick={onToggle}>
        {title}
        <span className="toggle-icon">{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
});

const MediSupportHub = () => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showScrollTop, setShowScrollTop] = useState(false);

  const {
    glossaryRef,
    glossaryAccordionRef,
    healthServiceRef,
    healthServiceAccordionRef,
    medicareRef,
    medicareAccordionRef,
    bulkBillingRef,
    bulkBillingAccordionRef,
    prepareGPRef,
    prepareGPAccordionRef,
    bookGPRef,
    bookGPAccordionRef,
    emergencyContactsRef,
    emergencyContactsAccordionRef,
    scrollAndOpenAccordion
  } = useScrollSections();

  const handleToggle = (accordionName) => {
    setOpenAccordion(openAccordion === accordionName ? null : accordionName);
  };

  // List of emergency contacts
  const contacts = [
    {
      title: "Emergency Call",
      description: "For very serious health issues, like unstoppable bleeding or broken bones, call for an ambulance.",
      timeLabel: "24/7",
      phoneNumber: "000",
      additionalInfo: "Inform them where you are, and expect an ambulance fee."
    },
    {
      title: "Lifeline Call",
      description: "Offers crisis support and suicide prevention services for Australians in emotional distress.",
      timeLabel: "24/7",
      phoneNumber: "13 11 14",
      additionalInfo: "Provides short-term, confidential, one-on-one support."
    },
    {
      title: "Interpreting Service (TIS)",
      description: "For people with limited English or for agencies needing help communicating with non-English speakers.",
      timeLabel: "24/7",
      phoneNumber: "131 450",
      additionalInfo: "None"
    },
    {
      title: "National Relay Service (NRS)",
      description: "Helps people who are deaf or have trouble hearing convert text to speech and communicate via phone.",
      timeLabel: "24/7",
      phoneNumber: "1800 555 677 (TTY)",
      additionalInfo: "This service is free and confidential."
    },
    {
      title: "Hearing or Speech Impairment Service",
      description: "A text-based emergency service for those with hearing or speech impairments, using a TTY device.",
      timeLabel: "24/7",
      phoneNumber: "106 (TTY)",
      additionalInfo: "It does not accept regular voice calls."
    },
    {
      title: "NURSE-ON-CALL",
      description: "A telephone service providing immediate professional health advice from registered nurses.",
      timeLabel: "24/7",
      phoneNumber: "1300 60 60 24",
      additionalInfo: "Nurses offer advice but cannot fully diagnose symptoms."
    },
    {
      title: "Victorian Poisons Information Centre",
      description: "For advice on poisoning, overdose, incorrect medication, or stings and bites.",
      timeLabel: "24/7",
      phoneNumber: "13 11 26",
      additionalInfo: "None"
    },
    {
      title: "Palliative Care Advisory Service",
      description: "For clinicians and the public seeking advice about life-limiting illnesses and palliative care.",
      timeLabel: "7:00am - 10:00pm",
      phoneNumber: "1800 360 000",
      additionalInfo: "None"
    },
    {
      title: "Women's Information Referral Exchange (WIRE)",
      description: "A free service providing help and advice for women facing various issues and challenges.",
      timeLabel: "Mon-Fri: 9:00am - 5:00pm",
      phoneNumber: "1300 134 130",
      additionalInfo: "None"
    },
    {
      title: "Domestic Violence Service",
      description: "Specialist support for individuals experiencing or fearing domestic violence and abuse.",
      timeLabel: "24/7",
      phoneNumber: "1800 015 188",
      additionalInfo: "None"
    },
    {
      title: "CASA House",
      description: "Offers advice and support for victims and survivors of sexual assault and violence.",
      timeLabel: "24/7",
      phoneNumber: "(03) 9635 3610",
      additionalInfo: "None"
    },
    {
      title: "Beyond Blue",
      description: "Provides immediate mental health support and advice for individuals in distress.",
      timeLabel: "24/7",
      phoneNumber: "1300 224 636",
      additionalInfo: "None"
    }
  ];
  

  // Function to filter contacts based on search query
  const filteredContacts = contacts.filter(contact =>
    contact.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle search when the "Search" button is clicked
  const handleSearch = () => {
    setSearchQuery(searchQuery);
  };

  // Function to reset search results
  const handleReset = () => {
    setSearchQuery('');
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Show or hide the "Back to Top" button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {  
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="medical-hub">
      <h1 className="centered-header">MediSupport Hub</h1>
      <p className="introduction">Explore essential medical information designed to support your healthcare journey. Click on the icons below to access detailed resources, from understanding Medicare benefits to preparing for your GP visit. Protect your health and well-being with the MediSupport Hub.</p>
      <FlowChart scrollToSection={scrollAndOpenAccordion} />
      <div className="accordions">
        <Accordion
          title="Glossary of terms"
          ref={glossaryAccordionRef}
          isOpen={openAccordion === 'glossary'}
          onToggle={() => handleToggle('glossary')}
        >
          <div ref={glossaryRef}></div>
          <TermCarousel />
        </Accordion>
        <Accordion
          title="Health Benefit in Australia"
          ref={healthServiceAccordionRef}
          isOpen={openAccordion === 'healthService'}
          onToggle={() => handleToggle('healthService')}
        >
          <div ref={healthServiceRef}></div>
          <HealthBenefit />
        </Accordion>
        <Accordion
          title="Application for Medicare"
          ref={medicareAccordionRef}
          isOpen={openAccordion === 'medicare'}
          onToggle={() => handleToggle('medicare')}
        >
          <div ref={medicareRef}></div>
          <ApplicationForMedicare />
        </Accordion>
        <Accordion
          title="Bulk Billing"
          ref={bulkBillingAccordionRef}
          isOpen={openAccordion === 'bulkBilling'}
          onToggle={() => handleToggle('bulkBilling')}
        >
          <div ref={bulkBillingRef}></div>
          <BulkBilling />
        </Accordion>
        <Accordion
          title="Preparation and List"
          ref={prepareGPAccordionRef}
          isOpen={openAccordion === 'prepareGP'}
          onToggle={() => handleToggle('prepareGP')}
        >
          <div ref={prepareGPRef}></div>
          <Checklist />
        </Accordion>
        <Accordion
          title="Booking a GP Appointment"
          ref={bookGPAccordionRef}
          isOpen={openAccordion === 'bookGP'}
          onToggle={() => handleToggle('bookGP')}
        >
          <div ref={bookGPRef}></div>
          <p style={{ textAlign: 'center',margin:'20px',fontWeight:'bold' }}>
            This picture will guide you understand the process of booking an appointment with a GP.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={booking} alt="GP Booking Process" style={{ width: '100%', maxWidth: '600px', margin: '20px 0' }} />
          </div>
        </Accordion>
        <Accordion
          title="Emergency Contacts"
          ref={emergencyContactsAccordionRef}
          isOpen={openAccordion === 'emergencyContacts'}
          onToggle={() => handleToggle('emergencyContacts')}
        >
          <div ref={emergencyContactsRef}></div>
          <div className="emergency-container">
              <p className="bold-text">Input contact to search and click close 'X' icon to clear search</p>

              {/* Search Bar */}
              <div className="search-container">
                <div className="search-wrapper">
                  <input
                    type="text"
                    placeholder="Search contacts..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input-call"
                  />
                  {searchQuery && (
                    <button
                      onClick={handleReset}
                      className="clear-search-button"
                    >
                      &times;
                    </button>
                  )}
                </div>
                <button onClick={handleSearch} className="search-button">Search</button>
              </div>

            <div className="card-container">
              {filteredContacts.length > 0 ? (
                filteredContacts.map((contact, index) => (
                  <ContactCard
                    key={index}
                    title={contact.title}
                    description={contact.description}
                    timeLabel={contact.timeLabel}
                    phoneNumber={contact.phoneNumber}
                    additionalInfo={contact.additionalInfo}
                  />
                ))
              ) : (
                <p>No results</p>
              )}
            </div>
          </div>  
        </Accordion>
      </div> 
      {/* "Back to Top" button */}
      {showScrollTop && (
        <button className="scroll-top-button" onClick={scrollToTop}>
          <FaArrowUp size={30} />
        </button>
      )}
    </div>
  );
};

export default MediSupportHub;

function ApplicationForMedicare() {
  return (
    <div className="medicare-container">
      <h3 className="medicare-title">What is Medicare?</h3>
      <p>
        Medicare is Australia's universal healthcare system designed to provide subsidized medical services to all Australian citizens and eligible residents. 
        The coverage includes doctor visits, hospital treatment, subsidized medications, and more.
      </p>

      <h3 className="medicare-title">Medicare Benefits for Refugees in Australia</h3>
      <p>
        Refugees in Australia are generally eligible for Medicare benefits, which means they can access the same medical services as other Australian residents, including:
      </p>
      <ul className="medicare-list">
        <li><strong>Subsidized Doctor Visits:</strong> Medicare can help cover part or all of the cost of seeing a General Practitioner (GP) or a specialist.</li>
        <li><strong>Hospital Treatment:</strong> Refugees can receive free hospital treatment as public patients, with costs covered by Medicare.</li>
        <li><strong>Subsidized Medications:</strong> Medicare helps pay for prescription medications through the Pharmaceutical Benefits Scheme (PBS).</li>
        <li><strong>Diagnostic Tests:</strong> Medicare also subsidizes medical diagnostic services, such as X-rays and blood tests.</li>
      </ul>

      <h3 className="medicare-title">How Refugees Can Apply for Medicare</h3>
      <p>
        Refugees can apply for Medicare by following these steps:
      </p>
      <ol className="medicare-list">
        <li><strong>Prepare Documentation:</strong> Refugees need to provide identification documents, such as a refugee certificate or passport, and complete the Medicare application form.</li>
        <li><strong>Submit Application:</strong> Applications can be submitted in person at a local Medicare service center or online. The application form can be downloaded from the Medicare website or obtained at the service center.</li>
        <li><strong>Wait for Approval:</strong> After submission, Medicare will review the applicant's eligibility and issue a Medicare card upon approval.</li>
        <li><strong>Receive Medicare Card:</strong> Once approved, applicants will receive a Medicare card, which they can present when seeking medical services to access Medicare benefits.</li>
      </ol>
    </div>
  );
}

function BulkBilling() {
  return (
    <div className="bulk-billing-container">
      <h3 className="bulk-billing-title">What is Bulk Billing?</h3>
      <p>
        Bulk Billing is a payment method within the Australian healthcare system that allows healthcare providers (such as General Practitioners, specialists, and diagnostic services) to bill Medicare directly, 
        ensuring that patients do not have to pay out-of-pocket for medical services. This payment method is particularly important for refugees, 
        as it ensures they can access necessary medical services without incurring any costs.
      </p>

      <h3 className="bulk-billing-title">How Refugees Can Use Bulk Billing</h3>
      <ul className="bulk-billing-list">
        <li><strong>Medicare Card:</strong> Refugees must have a valid Medicare card to access Bulk Billing services. When holding a Medicare card, refugees should confirm with the doctor whether Bulk Billing is available at the time of the visit.</li>
        <li><strong>Choosing a Bulk Billing Doctor:</strong> Not all doctors offer Bulk Billing services, so refugees should select doctors or medical centers that do provide Bulk Billing.</li>
        <li><strong>Booking and Attending Appointments:</strong> When making an appointment, it’s advisable to ask whether the doctor offers Bulk Billing. If so, refugees need only present their Medicare card during the visit and will not be required to pay any additional fees.</li>
      </ul>

      <h3 className="bulk-billing-title">Conditions for Bulk Billing</h3>
      <ul className="bulk-billing-list">
        <li><strong>Medicare Card Holder:</strong> Refugees must hold a valid Medicare card.</li>
        <li><strong>Doctor Participating in Bulk Billing:</strong> The healthcare provider or medical center must participate in the Bulk Billing scheme.</li>
      </ul>

      <h3 className="bulk-billing-title">Services Covered by Bulk Billing</h3>
      <ul className="bulk-billing-list">
        <li><strong>General Practitioner Visits:</strong> Includes initial consultations, routine check-ups, and minor ailments.</li>
        <li><strong>Public Hospital Services:</strong> Covers consultations, hospital stays, and treatments in public hospitals.</li>
        <li><strong>Basic Diagnostic Services:</strong> Includes necessary diagnostic services such as blood tests and X-rays.</li>
        <li><strong>Certain Specialist Services:</strong> Covers necessary specialist consultations and treatments.</li>
      </ul>
    </div>
  );
}

