import React, { useState } from 'react';
import jsPDF from 'jspdf';
import './Checklist.css';
import logo from '../Pictures/logo.png';

const Checklist = () => {

  const initialChecklistContent = {
    beforeSeeingGP: [
      'Record your symptoms.',
      'Note when symptoms occur.',
      'Note how long they last.',
      'List what you were doing before symptoms.',
      'List the most important questions.',
      'Prepare your medical history.',
      'Check family medical history.',
      'Write down your medications and dosages.',
      'Include over-the-counter medications.',
      'Include vitamins and herbal remedies.',
      'Get copies of recent tests.',
      'Bring your Medicare card.',
      'Bring health care and insurance details.',
      'Arrive at the clinic early.'
    ],
    whenTalkingToGP: [
      'Be honest with your GP.',
      'Describe symptoms clearly.',
      'Ask about treatment options.',
      'Understand your instructions.',
      'Bring support or take notes.'
    ]
  };

  const [checklist, setChecklist] = useState({
    beforeSeeingGP: initialChecklistContent.beforeSeeingGP.map(() => false),
    whenTalkingToGP: initialChecklistContent.whenTalkingToGP.map(() => false)
  });

  const [customItems, setCustomItems] = useState({
    beforeSeeingGP: [],
    whenTalkingToGP: []
  });

  const [newItem, setNewItem] = useState('');

  const handleChange = (section, index) => {
    setChecklist({
      ...checklist,
      [section]: checklist[section].map((item, i) => (i === index ? !item : item))
    });
  };

  const handleAddCustomItem = (section) => {
    if (newItem.trim() === '') {
      alert('Please add content first');
      return;
    }

    setCustomItems({
      ...customItems,
      [section]: [...customItems[section], newItem]
    });
    setChecklist({
      ...checklist,
      [section]: [...checklist[section], false]
    });
    setNewItem('');
  };

  const downloadChecklist = (section) => {
    const doc = new jsPDF();
    doc.setFontSize(20);

    let contentToDownload = initialChecklistContent[section].filter((_, index) => checklist[section][index]);

    const customContent = customItems[section].filter((_, index) => checklist[section][initialChecklistContent[section].length + index]);

    contentToDownload = contentToDownload.concat(customContent);

    if (contentToDownload.length === 0) {
      alert('Please select at least one item to download.');
      return;
    }

    if (section === 'beforeSeeingGP') {
      doc.text("Preparation list", 105, 20, null, null, 'center');
    } else if (section === 'whenTalkingToGP') {
      doc.text("What you should talk to the doctor", 105, 20, null, null, 'center');
    }

    doc.setFontSize(14);
    contentToDownload.forEach((text, index) => {
      doc.text(`${index + 1}. ${text}`, 20, 40 + index * 10);
    });

    const image = new Image();
    const logoWidth = 60;
    const logoHeight = (logoWidth * 17) / 100;
    image.src = logo;
    image.onload = function () {
      doc.addImage(image, 'PNG', 85, 260, logoWidth, logoHeight); // Adjust to center the logo at the bottom
      doc.save(`${section}_checklist.pdf`);
    };
  };

  return (
    <div className="checklist-container">
    <p className="text-center my-4">Use a checklist to remind yourself what you need to prepare</p>
    <div className="row">
      {/* Before Seeing GP */}
      <div className="col-md-6">
        <div className="card mb-4 checklist-box">
          <div className="card-body">
          <h3>Before seeing a GP</h3>
          {initialChecklistContent.beforeSeeingGP.map((item, index) => (
            <div className="form-check checklist-item" key={index}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={checklist.beforeSeeingGP[index]}
                onChange={() => handleChange('beforeSeeingGP', index)}
              />
              <label className="form-check-label">{item}</label>
            </div>
          ))}
          {customItems.beforeSeeingGP.map((item, index) => (
            <div className="form-check checklist-item" key={initialChecklistContent.beforeSeeingGP.length + index}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={checklist.beforeSeeingGP[initialChecklistContent.beforeSeeingGP.length + index]}
                onChange={() => handleChange('beforeSeeingGP', initialChecklistContent.beforeSeeingGP.length + index)}
              />
              <label className="form-check-label">{item}</label>
            </div>  
          ))}
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Add a custom item"
              value={newItem}
              onChange={(e) => setNewItem(e.target.value)}
            />
            <button className="btn btn-outline-secondary" onClick={() => handleAddCustomItem('beforeSeeingGP')}>
              Add
            </button>
          </div>
          <button className="btn btn-primary download-button" onClick={() => downloadChecklist('beforeSeeingGP')}>
            Download
          </button>
          </div>
          </div>
        </div>

        {/* When Talking to GP */}
        <div className="col-md-6">
          <div className="card mb-4 checklist-box">
            <div className="card-body">
              <h3>When talking to GP</h3>
          {initialChecklistContent.whenTalkingToGP.map((item, index) => (
            <div className="form-check checklist-item" key={index}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={checklist.whenTalkingToGP[index]}
                onChange={() => handleChange('whenTalkingToGP', index)}
              />
              <label className="form-check-label">{item}</label>
            </div>
          ))}
          {customItems.whenTalkingToGP.map((item, index) => (
            <div className="form-check checklist-item" key={initialChecklistContent.whenTalkingToGP.length + index}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={checklist.whenTalkingToGP[initialChecklistContent.whenTalkingToGP.length + index]}
                onChange={() => handleChange('whenTalkingToGP', initialChecklistContent.whenTalkingToGP.length + index)}
              />
              <label className="form-check-label">{item}</label>
            </div>
          ))}
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Add a custom item"
              value={newItem}
              onChange={(e) => setNewItem(e.target.value)}
            />
            <button className="btn btn-outline-secondary" onClick={() => handleAddCustomItem('whenTalkingToGP')}>
              Add
            </button>
          </div>
          <button className="btn btn-primary download-button" onClick={() => downloadChecklist('whenTalkingToGP')}>
            Download
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
