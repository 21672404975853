import React from 'react';
import { FaHeartbeat, FaUserNurse, FaTooth, FaHome, FaSyringe, FaHeadSideVirus,FaHandHoldingMedical } from 'react-icons/fa';
import { MdTranslate } from 'react-icons/md';
import './HealthBenefit.css'; 

const HealthBenefit = () => {
  return (
    <div className="health-benefit-container">
      <p className="health-benefit-header">These are free or cheaper services for refugees who are in Australia</p>
      <div className="health-benefit-cards">
        {/* Medicare */}
        <div className="health-benefit-card">
          <FaHandHoldingMedical size={50} className="benefit-icon" />
          <h3 className="benefit-title">Medicare</h3>
          <p className="benefit-description">It provides your subsidised medical services.</p>
        </div>

        {/* Interpreter */}
        <div className="health-benefit-card">
          <MdTranslate size={50} className="benefit-icon" />
          <h3 className="benefit-title">Interpreter</h3>
          <p className="benefit-description">You have the right to an interpreter for all health care, call 131 450.</p>
        </div>

        {/* Refugee Health Assessment */}
        <div className="health-benefit-card">
          <FaHeartbeat size={50} className="benefit-icon" />
          <h3 className="benefit-title">Refugee Health Assessment</h3>
          <p className="benefit-description">It is important for you to get a refugee health assessment but ask your doctor if they offer it.</p>
        </div>

        {/* Refugee Health Nurse */}
        <div className="health-benefit-card">
          <FaUserNurse size={50} className="benefit-icon" />
          <h3 className="benefit-title">Refugee Health Nurse</h3>
          <p className="benefit-description">Refugee health nurses can help you with all your health needs.</p>
        </div>

        {/* Mental Health */}
        <div className="health-benefit-card">
          <FaHeadSideVirus size={50} className="benefit-icon" />
          <h3 className="benefit-title">Mental Health</h3>
          <p className="benefit-description">You can talk to your GP or call Beyond Blue about how they can help you if you are worried about your mental health.</p>
        </div>

        {/* Dental Care */}
        <div className="health-benefit-card">
          <FaTooth size={50} className="benefit-icon" />
          <h3 className="benefit-title">Dental Care</h3>
          <p className="benefit-description">Free dental check-ups are available for refugees; inform the service when booking and schedule an appointment soon after arrival as dentists can be busy.</p>
        </div>

        {/* Community Health */}
        <div className="health-benefit-card">
          <FaHome size={50} className="benefit-icon" />
          <h3 className="benefit-title">Community Health</h3>
          <p className="benefit-description">Community health centres offer health care services mainly for people on a low income.</p>
        </div>

        {/* Immunisation */}
        <div className="health-benefit-card">
          <FaSyringe size={50} className="benefit-icon" />
          <h3 className="benefit-title">Immunisation</h3>
          <p className="benefit-description">Ask your GP about what vaccinations you need.</p>
        </div>
      </div>
    </div>
  );
};

export default HealthBenefit;
