import React from 'react';
import './Footer.css'; 

const Footer = () => {
    return (
      <div className='footer'>
        <p>Copyright © 2024 MigrantWomenHealth | Powered by TA34</p>
      </div>
    );
  }


export default Footer;
