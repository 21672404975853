import React, { useState } from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import MediSupportHub from './Pages/MediSupportHub';
import GPLocator from './Pages/GPLocator';
import Event from './Pages/Event'
import Insight from './Pages/Insight';
import SymptomChecklist from './Pages/SymptomChecklist'
import PasswordModal from './Components/PasswordModal'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthenticationSuccess = () => {
    setIsAuthenticated(true);
  };

  return (
    <>
      {isAuthenticated ? (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mediSupport-hub" element={<MediSupportHub />} />
            <Route path="/gp-locator" element={<GPLocator />} />
            <Route path="/symptom-checklist" element={<SymptomChecklist />} />
            <Route path="/insight" element={<Insight />} />
           {/* <Route path="/event" element={<Event />} />
            <Route path="*" element={<Navigate to="/" />} />*/}
          </Routes>
          <Footer />
        </>
      ) : (
        <PasswordModal onAuthenticated={handleAuthenticationSuccess} />
      )}
    </>
  );
}

export default App;