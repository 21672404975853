import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';  
import { FiClock } from 'react-icons/fi';    
import { BsExclamationOctagon } from "react-icons/bs";
import './ContactCard.css';  

const ContactCard = ({ title, description, timeLabel, phoneNumber, additionalInfo }) => {
  return (
    <div className="contact-card">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="icon-text">
        <FiClock className="icon" />
        <span>{timeLabel}</span>
      </div>
      <div className="icon-text">
        <FaPhoneAlt className="icon" />
        <span>{phoneNumber}</span>
      </div>
      <div className="icon-text">
        <BsExclamationOctagon className="icon" />
        <span>{additionalInfo}</span>
      </div>
    </div>
  );
};

export default ContactCard;

