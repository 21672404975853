/* global google */
import React, { useEffect, useRef, useState } from 'react';
import Papa from 'papaparse';
import './GPLocator.css';
import { BiCurrentLocation } from 'react-icons/bi';
import { FaArrowUp } from 'react-icons/fa'; 

const GPLocator = () => {
  const mapRef = useRef(null);
  const inputRef = useRef(null);
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPlaceSelected, setIsPlaceSelected] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [userPosition, setUserPosition] = useState(null);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const infoWindowRef = useRef(null); // Ref to manage the info window instance

  // Initialize map
  useEffect(() => {
    const initializeMap = (position) => {
      if (mapRef.current && !map) {
        const mapInstance = new google.maps.Map(mapRef.current, {
          center: position,
          zoom: 12,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        });
        setMap(mapInstance);
        setUserPosition(position);

        // Create a single InfoWindow instance
        infoWindowRef.current = new google.maps.InfoWindow();
      }
    };

    const handleLocationError = (error) => {
      console.error('Error fetching location:', error);
      initializeMap({ lat: -37.8136, lng: 144.9631 });
    };

    if (isMapVisible) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            initializeMap(userLocation);
          },
          handleLocationError
        );
      } else {
        handleLocationError(new Error('Geolocation not supported'));
      }
    } else {
      setMap(null);
    }
  }, [isMapVisible, map]);

  // Load GP locations from CSV
  useEffect(() => {
    if (map) {
      Papa.parse('/cleaned_gp_locations.csv', {
        download: true,
        header: true,
        complete: (results) => {
          setLocations(results.data); // Store raw location data
        },
        error: (error) => {
          console.error('Error loading CSV:', error);
        },
      });
    }
  }, [map]);

  // Initialize Autocomplete
  useEffect(() => {
    if (map && !autocomplete) {
      const input = document.getElementById('autocomplete-input');
      

      const melbourneBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-37.9236, 144.7631), 
        new google.maps.LatLng(-37.7036, 145.1631)  
      );
  
      const autocompleteInstance = new google.maps.places.Autocomplete(input, {
        bounds: melbourneBounds,       
        strictBounds: true,                   
        componentRestrictions: { country: 'au' }, 
        types: ['geocode'],  
      });
  
      autocompleteInstance.setFields(['geometry', 'name', 'formatted_address']); 
  
      autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();
  
        if (place.geometry) {
          const location = place.geometry.location;
          
          setSearchQuery(place.formatted_address);
          map.panTo(location);
          setUserPosition({ lat: location.lat(), lng: location.lng() });
          showNearestMarkers({ lat: location.lat(), lng: location.lng() });
        }
      });
  
      setAutocomplete(autocompleteInstance);
    }
  }, [map]);
  

  const handleClearSearch = () => {
    setSearchQuery('');  
    setShowClearIcon(false);  
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    setShowClearIcon(e.target.value.length > 0);  
  };

  // Clear markers from the map
  const clearMarkers = () => {
    markers.forEach((marker) => marker.setMap(null)); // Remove markers from the map
    setMarkers([]); // Clear marker state
  };

  // Add markers to the map
  const addMarkersToMap = (locations, position) => {
    if (!map) return;

    clearMarkers(); // Clear existing markers before adding new ones

    const distances = locations
      .map((location) => {
        const { latitude, longitude, organization, address, opening_hours_json } = location;
        if (!latitude || !longitude) return null;

        const locationPosition = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          locationPosition,
          new google.maps.LatLng(position.lat, position.lng)
        );

        return { location, distance };
      })
      .filter(Boolean);

    distances.sort((a, b) => a.distance - b.distance);

    const nearestLocations = distances.slice(0, 5); // Get nearest 5 locations

    const newMarkers = nearestLocations.map(({ location }) => {
      const { latitude, longitude, organization, address, opening_hours_json } = location;
      const markerPosition = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      };

      const marker = new google.maps.Marker({
        position: markerPosition,
        map: map,
        title: organization,
      });

      let openingHours = '';
      try {
        if (opening_hours_json) {
          const openingHoursObj = JSON.parse(opening_hours_json);
          openingHours = Object.entries(openingHoursObj)
            .map(([day, hours]) => {
              const displayHours = hours.toLowerCase() === 'nan' ? 'Closed' : hours;
              return `<strong>${day}:</strong> ${displayHours}`;
            })
            .join('<br />');
        }
      } catch (error) {
        console.error('Error parsing opening hours JSON:', error);
      }

      const infoWindowContent = `
        <div>
          <strong>${organization}</strong><br />
          <span>${address}</span><br />
          ${openingHours ? `<div>${openingHours}</div>` : '<em>No opening hours available</em>'}
        </div>
      `;

      // Attach a click listener to the marker to show the info window
      marker.addListener('click', () => {
        infoWindowRef.current.setContent(infoWindowContent);
        infoWindowRef.current.open(map, marker);
      });

      return marker;
    });

    setMarkers(newMarkers); // Update markers state with new markers
  };

  // Show nearest markers based on position
  const showNearestMarkers = (position) => {
    if (!position || locations.length === 0) return;
    addMarkersToMap(locations, position);
  };

  // Handle search using address
  const handleSearch = () => {
    if (!map) return;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: searchQuery }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const location = results[0].geometry.location;
        map.panTo(location);
        map.setZoom(15);
        setUserPosition({ lat: location.lat(), lng: location.lng() });
        showNearestMarkers({ lat: location.lat(), lng: location.lng() });
      } else {
        alert('Search was unsuccessful, please try again.');
      }
    });
  };
  const [showScrollTop, setShowScrollTop] = useState(false);
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Show or hide the "Back to Top" button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {  
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle current location
  const handleCurrentLocation = () => {
    if (!map || !navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        map.panTo(userLocation);
        setUserPosition(userLocation);
        showNearestMarkers(userLocation);
      },
      (error) => {
        alert('Unable to retrieve current location.');
        console.error('Error fetching current location:', error);
      }
    );
  };

  return (
    <div className="gplocator container">
      <div className="gplocator-header">
        <h1>GP Locator</h1>
      </div>
      <div className="introduction">
        <p>Use our GP Locator to easily find nearby clinics. Enter an address or use your current location to discover local healthcare services with real-time details.</p>
        {/* Insert Map Instructions */}
      <MapInstructions />
      </div>

      

      <div className="map-controls">
        <button onClick={() => {
            setIsMapVisible(true);
            setIsIframeVisible(false);
          }}>GP Map</button>
        <button onClick={() => {
            setIsMapVisible(false);
            setIsIframeVisible(true);
          }}>Insight</button>
      </div>

      <div className="controls">
        <div className="search-bar-container">
          <input
            id="autocomplete-input"
            ref={inputRef}
            type="text"
            placeholder="Enter location"
            value={searchQuery}
            onChange={handleInputChange}
          />
          {showClearIcon && <p className="clear-btn" onClick={handleClearSearch}>x</p>}
        </div>
        <button onClick={handleSearch}>Search</button>
        <button onClick={handleCurrentLocation}>Use Current Location</button>
      </div>

      {isMapVisible && <div ref={mapRef} className="map" />} 
      {isIframeVisible && (
        <iframe
          id="map-frame"
          src="/map.html" 
          style={{ width: '100%', height: '900px', border: 'none' }}
        />
      )}
      {showScrollTop && (
        <button className="scroll-top-button" onClick={scrollToTop}>
          <FaArrowUp size={20} />
        </button>
      )}
    </div>
  );
};

export default GPLocator;

const MapInstructions = () => {
  return (
    <div className="instructions-container">
      <ol>
      <li>
          <strong>1.Allow Device to Access Current Location 📍</strong><br />
          Ensure your device <strong>allows access </strong>to your current location to find the nearest clinics.
        </li>
        <li>
          <strong>2.Enter Address or Postcode 🏠</strong><br />
          Type your address or postcode in the search bar and click <strong>Search</strong> to find clinics nearby.
        </li>
        <li>
          <strong>3.Use Current Location <BiCurrentLocation /></strong><br />
          Press the <strong>Use Current Location</strong> button to quickly locate clinics closest to your current position.
        </li>
        <li>
          <strong>4.View Clinic Details 🏥</strong><br />
          After clicking Search, arkers will appear on the map. Click on any <strong>Marker</strong> to view the clinic’s details.
        </li>
        <li>
          <strong>5.Get Insights 💡</strong><br />
          Click <strong>Insights</strong> to explore healthcare information and coverage for specific areas.
        </li>
      </ol>
    </div>
  );
};

