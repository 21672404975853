import React from 'react';
import './TermCard.css';

const TermCard = ({ term, description, Icon, imageSrc, flipped, onFlip }) => {
  return (
    <div className={`term-card ${flipped ? 'flipped' : ''}`} onClick={onFlip}>
      {!flipped ? (
        <div className="term-card-front">
          <p>{term}</p>
        </div>
      ) : (
        <div className="term-card-back">
          {imageSrc ? (
            <img src={imageSrc} alt={term} className="term-card-image" />
          ) : (
            <Icon size={120} margin={20}/>
          )}
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default TermCard;

