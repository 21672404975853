import React, { useState } from 'react';
import './PasswordModal.css';  // Import the new CSS file


const PasswordPage = ({ onAuthenticated }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const correctPassword = 'ta34mwh'; // Correct password

    if (password === correctPassword) {
      onAuthenticated();
    } else {
      setError('Incorrect password. Please try again.');
    }

    setPassword('');
  };

  return (
    <div className="password-page">
      <div className="password-container">
        <h1 className="password-title">Welcome to Migrant Women Health Website</h1>
        <p className="password-prompt">Please enter your password</p>
        <form onSubmit={handleSubmit} className="password-form">
          <div className="password-input-container">
            <span className="lock-icon">🔒</span>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="password-input"
            />
          </div>
          <button type="submit" className="password-submit">Submit</button>
          {error && <p className="password-error">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default PasswordPage;

